<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
  <mat-spinner *ngIf="isLoadingResults"> Cargando </mat-spinner>
  <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      
  </div>
</div>
<h2>SUJETO EXCLUIDO</h2>
<div *ngIf="activarFormulario">
  <div class="main-div">
    <mat-card>
      <mat-grid-list cols="2" rowHeight="5:1">
        <mat-grid-tile>
          Nombre o razón social: {{selectProv.pvnombr}}
          <br>
          Nombre Comercial: {{selectProv.pvnomng}}
          <br>
          Num Doc: {{selectProv.pvnit}}
          <br>
          Act. Economica: {{selectProv.pvgirng}}
          <br>
          Telefono: {{getTelefono()}}
          <br>
          Pais: {{selectProv.pvpais}}
        </mat-grid-tile>
        <mat-grid-tile>
          Nrc: {{selectProv.pvregis}}
          <br>
          Nit: {{selectProv.pvnit}}
          <br>
          Correo: {{selectProv.pvmail}}
          <br>
          Distrito:
          <br>
          Departamento:
          <br>
          Direccion: {{selectProv.pvdirec}}
        </mat-grid-tile>
      </mat-grid-list>

    </mat-card>
  </div>

  <div class="main-div">
  <mat-card>
      
      <div class="row">  
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Tipo Item</mat-label>
          <mat-select matNativeControl [(ngModel)]="selectedItem" name="tipo" required>
            <mat-option value="" selected></mat-option>
            <mat-option *ngFor="let item of tipoItem" [value]="item.codigo">
              {{ item.valor }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Cantidad</mat-label>
          <input matInput [(ngModel)]="item_create.cantidad" type="number" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Uni. Medida</mat-label>
          <mat-select matNativeControl [(ngModel)]="selectedUMedida" name="unimed" required>
            <mat-option value="" selected></mat-option>
            <mat-option *ngFor="let item of u_medida" [value]="item.codigo">
              {{ item.valor }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Descripcion</mat-label>
          <input matInput [(ngModel)]="item_create.descripcion" type="text" required>
        </mat-form-field>
      
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Precio Unitario</mat-label>
          <input matInput [(ngModel)]="item_create.precioUni" type="number" required>
        </mat-form-field>
      </div>
      
    
      
    <!--
      <div class="form-group">
        <label>Descu Item</label>
        <input matInput [(ngModel)]="item_create.montoDescu" type="number" (ngModelChange)="verificarCampos()"> 
      </div>
    -->
      <div class="row">
        <button mat-flat-button color="primary" (click)="agregarItem()" >Agregar</button>
        <button mat-flat-button color="primary" (click)="actualizarItem(item_create)" *ngIf="habilitarBtnActualizar">Actualizar</button>
      </div>
   
  </mat-card>  
</div>    
<div class="example-container">
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8" tabindex="0">
    <!-- No Column -->
    <ng-container matColumnDef="No">
      <mat-header-cell *matHeaderCellDef>No.</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" (click)="onEdit(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="onDelete(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef class="mobile-hide"></mat-footer-cell>
    </ng-container>
  
    <!-- Cantidad Column -->
    <ng-container matColumnDef="Cantidad">
      <mat-header-cell *matHeaderCellDef>Cantidad</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.cantidad}}</mat-cell>
      <mat-footer-cell *matFooterCellDef class="mobile-hide"></mat-footer-cell>
    </ng-container>
  
    <!-- Unidad Column -->
    <ng-container matColumnDef="Unidad">
      <mat-header-cell *matHeaderCellDef>U. Medida</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.uniMedida}}</mat-cell>
      <mat-footer-cell *matFooterCellDef class="mobile-hide"></mat-footer-cell>
    </ng-container>
  
    <!-- Descripcion Column -->
    <ng-container matColumnDef="Descripcion">
      <mat-header-cell *matHeaderCellDef>Descripcion</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.descripcion}}</mat-cell>
      <mat-footer-cell *matFooterCellDef class="mobile-hide"></mat-footer-cell>
    </ng-container>

    <!-- Precio Unitario Column -->
    <ng-container matColumnDef="PrecioUnitario">
        <mat-header-cell *matHeaderCellDef>Precio Unitario</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.precioUni  | currency:USD}}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="mobile-hide"></mat-footer-cell>
      </ng-container>

    <!-- Descuento Column -->
    <ng-container matColumnDef="Descuento">
        <mat-header-cell *matHeaderCellDef>Descuento por Item</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.montoDescu  | currency:USD}}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="mobile-hide"></mat-footer-cell>
      </ng-container>  
  
      <!-- OtrosMontosNoAfectos Column -->
    <ng-container matColumnDef="OtrosMontosNoAfectos">
        <mat-header-cell *matHeaderCellDef>Otros Montos no afectos</mat-header-cell>
        <mat-cell *matCellDef="let element">{{0| currency:USD}}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="mobile-hide"></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="VentasAfectas">
        <mat-header-cell *matHeaderCellDef>Ventas Afectas</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.compra | currency:USD}}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="mobile-hide"></mat-footer-cell>
      </ng-container> 
      <!-- empty footer row-->
    
     <ng-container matColumnDef="emptyFooter">
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container> 
    <!-- set footer for Sub-Total amount-->
    <ng-container matColumnDef="titulo">
      <mat-footer-cell *matFooterCellDef class="columna-concep">Sumatoria de Ventas</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="monto">
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        {{ getSumatoria() | currency:USD }}
      </mat-footer-cell>
    </ng-container>

    <!-- set footer for Sub-Total amount-->
    <ng-container matColumnDef="tituloTotal">
      <mat-footer-cell *matFooterCellDef class="columna-concep">Retencion</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="montoTotal">
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        {{ getRetencion() | currency:USD }}
      </mat-footer-cell>
    </ng-container>

    <!-- set footer for Sub-Total amount-->
    <ng-container matColumnDef="tituloTotalPagar">
      <mat-footer-cell *matFooterCellDef class="columna-concep">Total-Pagar</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="montoTotalPagar">
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        {{ getTotalPagar() | currency:USD }}
      </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <mat-footer-row *matFooterRowDef="displayedColumns" class="example-first-footer-row"></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayedColumnsSubtotal" class="example-first-footer-row"></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayedColumnsTotal" class="example-first-footer-row"></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayedColumnsTotalPagar" class="example-first-footer-row"></mat-footer-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
<div class="form-group form-group-button" *ngIf="activarFormulario">
  <button mat-flat-button color="primary" (click)="facturar()">GENERAR DTE</button>
</div> 
<div *ngIf="!activarFormulario">
  <mat-table [dataSource]="dataSourceConsulta" class="mat-elevation-z8" >
    <ng-container matColumnDef="Cod_Docu">
      <mat-header-cell *matHeaderCellDef>Cod Docu</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.coddocu}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Cliente">
      <mat-header-cell *matHeaderCellDef>Cliente</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.coment1}}  </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Cod_Generacion">
      <mat-header-cell *matHeaderCellDef>Cod Generacion</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.codigoGeneracion}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Acciones">
      <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="button-container" *ngIf="!esInvalidado(row)">
          <button  mat-flat-button color="primary" (click)="getDescargarPDF(row)">
            PDF
          </button>
          <button  mat-flat-button color="warn" (click)="invalidarDTE(row)">
            INVALIDAR
          </button>
        </div>  
        <div class="button-container" *ngIf="esInvalidado(row)">
          ANULADO
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsConsulta"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsConsulta;"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div> 