import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Famaord } from 'src/app/_model/famaord';
import { Ord_Client } from 'src/app/_model/ord_client';
import { FamaordService } from 'src/app/_service/famaord.service';

@Component({
  selector: 'app-dialog-ingresomanual',
  templateUrl: './dialog-ingresomanual.component.html',
  styleUrls: ['./dialog-ingresomanual.component.css']
})
export class DialogIngresomanualComponent implements OnInit {

  famaord : Famaord;
  ord_Cliente : Ord_Client;

  constructor(@Inject(MAT_DIALOG_DATA) private data: Ord_Client, private famaordService : FamaordService) {
    this.famaord = new Famaord();
    this.ord_Cliente = data;
   }

  ngOnInit(): void {
    console.log(this.ord_Cliente)
    this.famaord.famaord_enc = this.ord_Cliente.famaord_enc;
    this.famaord.numpedi = this.ord_Cliente.famaord_enc.numpedi;

  }

  agregar(){
    this.famaord.subtotal = this.famaord.valnuni * this.famaord.totunid
    this.famaordService.agregarProducto(this.famaord).subscribe(data=>{
      console.log(data)
    })
  }

  closeDialog(){

    
  }

}
