import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { DatosEmpresa } from 'src/app/_model/DatosEmpresa/DatosEmpresa';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatosempresaService {

  rolCambio = new Subject<DatosEmpresa[]>();
  mensajeCambio = new Subject<DatosEmpresa>();

  url: string = `${environment.HOST}/v1/datosempresa`;

  constructor(private http: HttpClient) { }
  
  listarPorId(id : number){
    return this.http.get<DatosEmpresa>(`${this.url}/${id}`,this.getToken());
  }

  getEmpresa(){
    return this.http.get<DatosEmpresa>(`${this.url}/datos`,this.getToken());
  }

  getEmpresas(){
    return this.http.get<DatosEmpresa[]>(`${this.url}/empresas`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
