// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  REINTENTOS: 0,
  HOST: 'https://api.sis2000.online:8443',
  //HOST: 'http://192.168.10.34:8080',
  //HOST: 'http://localhost:8080',
  //HOST: 'http://3.147.8.77:8080',
  //HOST: 'http://18.227.10.113:8080',//DGPruebas
  //HOST: 'http://3.22.250.79:8080',
  //HOST: 'http://10.3.10.131:8080',
  //HOST: 'http://192.168.0.93:8080',
  //HOST: 'http://192.168.1.10:8080',
  TOKEN_AUTH_USERNAME: 'sis2000app',
  TOKEN_AUTH_PASSWORD: 'multi2019codex',
  TOKEN_NAME: 'access_token'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
