<div class="example-container">
    <mat-toolbar color="primary" class="example-toolbar" *ngIf="loginService.estaLogeado()">
        <button mat-icon-button (click)="sidenav.toggle()">
            <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>
            <mat-icon *ngIf="sidenav.opened">close</mat-icon>
        </button>
  
        <h1 class="example-app-name">Sis2000 - {{nombre}}</h1>
  
        <span class="example-spacer"></span>

        <h1 class="example-app-name">{{currentDateTime}} - {{ rxTime | date: 'hh:mm:ss a' }}</h1>
  
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
  
        <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/dashboard">
                <mat-icon>home</mat-icon>
                <span>Inicio</span>
            </button>
            <button mat-menu-item (click)="sidenav.opened = false" (click)="loginService.cerrarSesion()">
                <mat-icon>exit_to_app</mat-icon>
                <span>Cerrar Sesión</span>
            </button>
        </mat-menu>
    </mat-toolbar>
  
    <mat-sidenav-container class="example-sidenav-container">
        <mat-sidenav #sidenav style="width: 200px">
            <mat-accordion >
                <mat-expansion-panel>
                  <mat-expansion-panel-header *ngIf="getmenusAdm()">
                    <mat-panel-title>
                        <mat-icon>build</mat-icon>
                        <span>Herramientas</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
    
                    <div *ngFor="let m of menusAdm">
                      <button mat-menu-item (click)="sidenav.toggle()" routerLink="{{m.url}}" style="cursor: pointer">              
                          <span>{{m.nombre}}</span>
                      </button>
                      <mat-divider></mat-divider>
                    </div>
    
                </mat-expansion-panel>
              </mat-accordion>
              <mat-divider></mat-divider>
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header *ngIf="getmenusCont()">
                    <mat-panel-title>
                        <mat-icon>build</mat-icon>
                        <span>Contabilidad</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
    
                    <div *ngFor="let m of menusCont">
                      <button mat-menu-item (click)="sidenav.toggle()" routerLink="{{m.url}}" style="cursor: pointer">              
                          <span>{{m.nombre}}</span>
                      </button>
                      <mat-divider></mat-divider>
                    </div>
    
                  </mat-expansion-panel>
              </mat-accordion>
              <mat-divider></mat-divider>

              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header *ngIf="getmenusCompras()">
                    <mat-panel-title>
                        <mat-icon>build</mat-icon>
                        <span>Compras</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
    
                    <div *ngFor="let m of menusCompra">
                      <button mat-menu-item (click)="sidenav.toggle()" routerLink="{{m.url}}" style="cursor: pointer">              
                          <span>{{m.nombre}}</span>
                      </button>
                      <mat-divider></mat-divider>
                    </div>
    
                  </mat-expansion-panel>
              </mat-accordion>
              <mat-divider></mat-divider>

              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header *ngIf="getmenusFac()">
                    <mat-panel-title>
                        <mat-icon>build</mat-icon>
                        <span>Ventas</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
    
                    <div *ngFor="let m of menusFactu">
                      <button mat-menu-item (click)="sidenav.toggle()" routerLink="{{m.url}}" style="cursor: pointer">              
                          <span>{{m.nombre}}</span>
                      </button>
                      <mat-divider></mat-divider>
                    </div>
    
                  </mat-expansion-panel>
              </mat-accordion>
              <mat-divider></mat-divider>
                
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header *ngIf="getmenusVtas()">
                    <mat-panel-title>
                        <mat-icon>build</mat-icon>
                        <span>Pedidos</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
    
                    <div *ngFor="let m of menusVtas">
                      <button mat-menu-item (click)="sidenav.toggle()" routerLink="{{m.url}}" style="cursor: pointer">              
                          <span>{{m.nombre}}</span>
                      </button>
                      <mat-divider></mat-divider>
                    </div>
    
                  </mat-expansion-panel>
              </mat-accordion>
              <mat-divider></mat-divider>

              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header *ngIf="getmenusEntr()">
                    <mat-panel-title>
                        <mat-icon>build</mat-icon>
                        <span>Entregas</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                    <div *ngFor="let m of menusEntr">
                      <button mat-menu-item (click)="sidenav.toggle()" routerLink="{{m.url}}" style="cursor: pointer">              
                          <span>{{m.nombre}}</span>
                      </button>
                      <mat-divider></mat-divider>
                    </div>
    
                  </mat-expansion-panel>
              </mat-accordion>
              <mat-divider></mat-divider>
              
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
  
  </div>