import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogsujetoexcluido',
  templateUrl: './dialogsujetoexcluido.component.html',
  styleUrls: ['./dialogsujetoexcluido.component.css']
})
export class DialogsujetoexcluidoComponent implements OnInit {

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(private dialogRef: MatDialogRef<DialogsujetoexcluidoComponent>) { }
  ngOnInit(): void {
  }

  consultarFechas(){
    this.dialogRef.close({event:'close',data:this.range});
  }

  cerrarDialog(){
    this.dialogRef.close();
  }

}
