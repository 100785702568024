import { ConfigpedidoService } from './../../../_service/configpedido/configpedido.service';
import { DatosEmpresa } from 'src/app/_model/DatosEmpresa/DatosEmpresa';
import { DatosempresaService } from './../../../_service/datosempresa/datosempresa.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {

  displayedColumns = ['nombre', 'nit','nrc','acciones'];
  dataSource: MatTableDataSource<DatosEmpresa>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private datosempresaService : DatosempresaService,private configpedidoService : ConfigpedidoService
  ) { }

  ngOnInit(  ): void {
    this.datosempresaService.getEmpresas().subscribe(empresas => {
      console.log(empresas)
      this.dataSource = new MatTableDataSource(empresas);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.configpedidoService.getConfiguraciones().subscribe(configuraciones =>{
      console.log(configuraciones)
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
