<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        {{msj_spinner}}
    </div>
</div>

<mat-card>
    <h3>Inventario de Rutas</h3>
    <div class="example-header">
        <div class="row">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
        </mat-form-field>
        TOTAL UNIDADES: {{totalUnidades_txt}}
        <mat-form-field>
            <mat-label>Ruta</mat-label>
                <mat-select  name="ruta" (selectionChange)="onRutaChange($event)">
                <mat-option *ngFor="let rt of rutas" [value]="rt">
                    {{ rt }}
                </mat-option>
            </mat-select>
        </mat-form-field>
            <button mat-flat-button color="primary" (click)="verTodos()">VER TODOS</button>
            <button mat-flat-button color="primary" (click)="verconexistencias()" >VER CON EXISTENCIA</button>
        </div>
    </div>
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="codRutaRt" >
            <mat-header-cell *matHeaderCellDef>Cod. Ruta</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.codRutaRt}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="codProdRt" >
            <mat-header-cell *matHeaderCellDef>Cod. Producto</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.codProdRt}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="descrip" >
            <mat-header-cell *matHeaderCellDef>Producto</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{nombreProducto(row.codProdRt)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="preUnitRt" >
            <mat-header-cell *matHeaderCellDef>P. Unit</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.preUnitRt | currency}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="existencia" >
            <mat-header-cell *matHeaderCellDef>Existencia</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{getExistencia(row)}}.U </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
        </mat-row>
    </mat-table>
</mat-card>