<div>
    <div class="example-container mat-elevation-z8">
    </div>
    <mat-card class="example-card center">
        <mat-card-header class="centerContent">
            <mat-card-title>Catalogo de Clientes
                <span class="spacer"></span>
                        
            </mat-card-title>
        </mat-card-header>
        <mat-tab-group>
            <mat-tab label="Información General">
                <!--<form [formGroup]="clienteForm">-->
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Código</mat-label>
                        <input matInput [(ngModel)]="ccmacli.codclie"  />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Negocio</mat-label>
                        <input matInput [(ngModel)]="ccmacli.nomnego" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Nombre NRC</mat-label>
                        <input matInput [(ngModel)]="ccmacli.nomclie" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Dirección</mat-label>
                        <input matInput [(ngModel)]="ccmacli.dirclie" />
                    </mat-form-field>

                    <div class="row">
                        <mat-form-field appearance="outline" class="half-width">
                          <mat-label>Departamentos</mat-label>
                          <mat-select  name="departamentoClie" [(ngModel)]="departamentoClie" (selectionChange)="onDepartamentoChange($event)">
                            <mat-option *ngFor="let depto of departamentos" [value]="depto">
                                {{ depto.nomdep }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field> 
                        <mat-form-field appearance="outline" class="half-width">
                          <mat-label>Municipio</mat-label>
                          <mat-select >
                            <mat-option *ngFor="let muni of municipios" [value]="muni">
                              {{ muni }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Contacto</mat-label>
                        <input matInput [(ngModel)]="ccmacli.celcontac" />
                    </mat-form-field>
                    
                    <div class="row">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Telefono</mat-label>
                            <input matInput [(ngModel)]="ccmacli.telnego"/>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>WhatsApp</mat-label>
                            <input matInput [(ngModel)]="ccmacli.celwapp" />
                        </mat-form-field>
                    </div>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Correo Contacto</mat-label>
                        <input matInput [(ngModel)]="ccmacli.mail_alt" />
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Correo DTE</mat-label>
                        <input matInput [(ngModel)]="ccmacli.mail_dte" />
                    </mat-form-field>
                <!--</form>-->
            </mat-tab>
            <mat-tab label="Información Fiscal">
                <!--<form [formGroup]="clienteForm">-->
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>NRC</mat-label>
                    <input matInput [(ngModel)]="ccmacli.nrcclie" />
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>NIT</mat-label>
                    <input matInput [(ngModel)]="ccmacli.nitclie" />
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Giro</mat-label>
                    <input matInput [(ngModel)]="ccmacli.mhcodgiro" />
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Nombre NIT</mat-label>
                    <input matInput [(ngModel)]="ccmacli.nitnomb" />
                  </mat-form-field>
                <!--</form>-->
            </mat-tab>
            <mat-tab label="Movimientos">
                <!--<form [formGroup]="clienteForm">-->
                    <div class="row">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Sal Inicial</mat-label>
                            <input matInput [(ngModel)]="ccmacli.vsalini" readonly="true"/>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Compras</mat-label>
                            <input matInput [(ngModel)]="ccmacli.vcarmes" readonly="true"/>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Pagos</mat-label>
                            <input matInput [(ngModel)]="ccmacli.vabomes" readonly="true"/>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Saldo Final</mat-label>
                            <input matInput [(ngModel)]="ccmacli.vcarmes" readonly="true"/>
                        </mat-form-field>
                    </div>
                <!--</form>-->
            </mat-tab>
        </mat-tab-group>
        <div>
            <button mat-raised-button color="primary" (click)="onAddCliente()">Agregar Cliente</button>
            <button mat-raised-button color="primary" (click)="onModCliente()">Modificar Cliente</button>
            <button mat-raised-button color="warn" (click)="onDeleteCliente()">Inactivar Cliente</button>
        </div>    
    </mat-card>
</div>
