export class Famadoc{
    coddocu : string;
    autfisc : string;
    numfisc : number;
    clasnot : string;
    tipdafe : string;
    numdafe : string;
    codprod : string;
    forpago : number;
    numpedi : number;
    fecpedi : string;
    numnrem : number;
    diacred : number;
    rutavta : string;
    codclie : string;
    codtien : string;
    totunid : number;
    cancaja : number;
    fracaja : number;
    valnuni : number;
    tipdesc : string;
    desprod : number;
    desfact : number;
    desespe : number;
    valinco : number;
    clasvta : string;
    tipvtas : string;
    tipempq : string;
    porimpu : number;
    ctrlcvs : string;
    cocobol : string;
    fecdocu : string;
    ctrlrec : string;
    compart : string;
    bodsale : string;
    compdes : string;
    cdprinv : string;
    codbnco : string;
    valpago : number;
    valefec : number;
    porcomi : number;
    ctrlret : number;
    numcheq : number;
    idcortc : string;
    poriret : number;
    porxcob : number;
    limiret : number;
}