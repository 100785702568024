import { DtoSujetoExcluido } from './../../_model/DTO/DTO_SujetoExcluido';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SujetoExcluido } from 'src/app/_model/dte/SujetoExcluido/sujetoexcluido';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SujetoexcluidodteService {

  sujetoExcluidoCambio = new Subject<SujetoExcluido[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/sujetoexcluido_dte`;
  
  constructor(private http: HttpClient) { }

  generarJsonDteFe_Fse(dtoSujetoExcluido : DtoSujetoExcluido){
    //return this.http.post(this.url, dtoSujetoExcluido,this.getToken());
    console.log('generarccfdte')
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.post(this.url,dtoSujetoExcluido, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
        
      })
    }).pipe(
      catchError((error: HttpErrorResponse) => {
          if (error.status === 400 && error.error instanceof Blob) {
              // Assuming the error response is a JSON string in a Blob
              return new Observable(observer => {
                  const reader = new FileReader();
                  reader.onload = () => {
                      const text = reader.result as string;
                      console.log(text);
                      //const jsonResponse = JSON.parse(text);
                      observer.error(text);  // Emit the JSON error response
                  };
                  reader.onerror = () => {
                      observer.error('Failed to read error response');
                  };
                  reader.readAsText(error.error);
              });
          } else {
              return throwError(error);
          }
      })
  );
  }

  generarfse_pdf(coddocu : string){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/generarfse_pdf/${coddocu}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
        
      })
    }).pipe(
      catchError((error: HttpErrorResponse) => {
          if (error.status === 400 && error.error instanceof Blob) {
              // Assuming the error response is a JSON string in a Blob
              return new Observable(observer => {
                  const reader = new FileReader();
                  reader.onload = () => {
                      const text = reader.result as string;
                      console.log(text);
                      //const jsonResponse = JSON.parse(text);
                      observer.error(text);  // Emit the JSON error response
                  };
                  reader.onerror = () => {
                      observer.error('Failed to read error response');
                  };
                  reader.readAsText(error.error);
              });
          } else {
              return throwError(error);
          }
      })
  );
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
