<div class="example-container">
    <mat-card-header class="centerContent">
        <!-- <mat-card-title>{{famaord.inbogrl.codigo_ln}} - {{famaord.inbogrl.descrip_ln}}</mat-card-title> -->
    </mat-card-header>
    <mat-grid-list cols="2" rowHeight="50px">
        <mat-grid-tile  colspan="2" rowspan="1" >
            <mat-form-field>
                <label for="famaord.cancaja">Codigo</label>
                <input type="string" matInput [(ngModel)]="famaord.codprod" name="famaord.codprod" disabled=false> 
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile  colspan="2" rowspan="1" >
            <mat-form-field>
                <label for="famaord.cancaja">Descripcion</label>
                <input type="string" matInput [(ngModel)]="famaord.coment1" name="famaord.coment1" disabled=false> 
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile  colspan="2" rowspan="1" >
            <mat-form-field>
                <label for="famaord.cancaja">Precio</label>
                <input type="number" matInput [(ngModel)]="famaord.valnuni" name="famaord.valnuni" disabled=false> 
            </mat-form-field>
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="2" rowHeight="50px">
        <mat-grid-tile  colspan="1" rowspan="1" >
            <mat-form-field>
                <label for="famaord.totunid">Unidades</label>
                <input type="number" matInput [(ngModel)]="famaord.totunid" name="famaord.totunid" disabled=false>
            </mat-form-field>
            </mat-grid-tile>
        <mat-grid-tile colspan="2" >
            <button type="submit" mat-raised-button color="primary" (click)="agregar()" >Agregar</button>
        </mat-grid-tile>
    </mat-grid-list>
</div>
