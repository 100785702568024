import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { ConfigPedido } from 'src/app/_model/Configuracion/ConfigPedido/ConfigPedido';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigpedidoService {

  configPedidoCambio = new Subject<ConfigPedido[]>();
  mensajeCambio = new Subject<ConfigPedido>();

  url: string = `${environment.HOST}/v1/configpedido`;

  constructor(private http: HttpClient) { }
  
  listarPorId(id : number){
    return this.http.get<ConfigPedido>(`${this.url}/${id}`,this.getToken());
  }

  getConfiguraciones(){
    return this.http.get<ConfigPedido>(`${this.url}/configuraciones`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
