import { InbogrlService } from 'src/app/_service/inbogrl.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { element } from 'protractor';
import { Inborut } from 'src/app/_model/invetario/inborut';
import { InborutService } from 'src/app/_service/inventario/inborut.service';
import { Inbogrl } from 'src/app/_model/inbogrl';

@Component({
  selector: 'app-inventariorutas',
  templateUrl: './inventariorutas.component.html',
  styleUrls: ['./inventariorutas.component.css']
})
export class InventariorutasComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;

  rutas = ['1','2','3','4','5','6','8','9','10','11']
  totalUnidades_txt = 0;

  listProductos : Inborut[] =  new Array();
  listInbogrl : Inbogrl[] = new Array();

  displayedColumns = ['codRutaRt', 'codProdRt', 'descrip' ,'preUnitRt','existencia'];
  dataSource: MatTableDataSource<Inborut>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private inborutService : InborutService, private inbogrlService : InbogrlService) {
    this.inborutService.ListarProductos().subscribe(productos => {
      this.listProductos = productos;
      this.inborutService.InbogrlCambio.next(productos);
    })
   }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.inbogrlService.listar().subscribe(data => {
      this.listInbogrl = data;
      console.log(this.listInbogrl)
      this.inborutService.InbogrlCambio.subscribe(data=>{
        if(data != null){
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.totalUnidades_txt = this.totalUnidades(data);
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
  
        }else{
          this.totalUnidades_txt = 0;
        }
      })
      this.verTodos();
    })

    
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getExistencia(row : Inborut){
    //uexiini_rt+uingmes_rt-uegrmes_rt+uingtem_rt-uegrtem_rt
    return row.uExiIniRt+row.uIngMesRt-row.uIngMesRt+row.uIngTemRt-row.uEgrTemRt
  }

  onRutaChange(event: any){
    const selectedRuta = event.value;
    this.inborutService.ProductoBodega(selectedRuta).subscribe(productos =>{
      this.totalUnidades_txt = this.totalUnidades(productos);
      this.listProductos = productos;
      this.inborutService.InbogrlCambio.next(productos)
    })
  }

  totalUnidades(productos : Inborut[]){
    let conteo : number = 0
    productos.forEach(element => {
      conteo = conteo + this.getExistencia(element)
    });
    return conteo;
  }

  verTodos(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.inborutService.ListarProductos().subscribe(productos => {
      this.listProductos = productos;
      this.inborutService.InbogrlCambio.next(productos);
      this.isLoadingResults = false;
        this.isRateLimitReached = false;

    })
  }

  verconexistencias(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    let newList : Inborut[] = new Array;
    if(this.listProductos.length > 0 && this.listProductos != null){
      this.listProductos.forEach(element => {
        if(this.getExistencia(element)>0){
          newList.push(element);
        }
      });
      this.inborutService.InbogrlCambio.next(newList);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    }
  }

  nombreProducto(codigo : string){
    return 'NO ENCONTRADO'
  }

}
