<div class="form-field-container">
    <mat-form-field >
        <mat-label>Seleccione un rango de fecha</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Fecha Inicio">
          <input matEndDate formControlName="end" placeholder="Fecha FIn">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
    <p>Fecha de Inicio: {{range.value.start | date }}</p>
    <p>Fecha de Fin: {{range.value.end| date }}</p>
  
  <div class="button-container">
    <button  mat-flat-button color="primary" (click)="consultarFechas();">Consultar</button>
    <button mat-flat-button color="warn" (click)="cerrarDialog();">Cerrar</button>
  </div>
