import { DatosempresaService } from './_service/datosempresa/datosempresa.service';
import { UsuarioService } from './_service/seguridad/usuario.service';
import { Component, OnInit } from '@angular/core';
import { Menu } from './_model/seguridad/menu';
import { LoginService } from './_service/seguridad/login.service';
import { MenuService } from './_service/seguridad/menu.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs/internal/Subscription';
import { timer } from 'rxjs/internal/observable/timer';
import { map, share } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
 
  title = 'frontend';

  menus: Menu[] = [];
  menusAdm : Menu[] = [];
  menusVtas : Menu[] = [];
  menusCont : Menu[] = [];
  menusEntr : Menu[] = [];
  menusFactu : Menu[] = [];
  menusCompra: Menu[] = [];

  actMenuAdm : Boolean = false;
  actMenuVtas : Boolean = false;
  actMenuCont : Boolean = false;
  actMenuEntr : Boolean = false;
  actMenuFactu : Boolean = false;
  actMenuCompra : Boolean = false;

  nombre : string;
  rxTime = new Date();
  subscription: Subscription;
  intervalId;
  currentDateTime : any;
  
  constructor(public datepipe: DatePipe,public loginService : LoginService, private menuService : MenuService, 
    private usuarioService : UsuarioService, private datosempresaService : DatosempresaService){
    this.currentDateTime = this.datepipe.transform((new Date), 'dd/MM/yyyy');
    
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
      
    this.menuService.menuCambio.subscribe(data => {
      this.menus = data;
      //console.log(this.menus);
      this.menusAdm.length = 0;
      for (let item of this.menus) {
        if (item.idMenu == 4) {
            this.menusAdm.push(item);
        }
        if (item.idMenu == 5) {
          this.menusAdm.push(item);
        }
        if (item.idMenu == 6) {
          this.menusAdm.push(item);
        }
        if (item.idMenu == 10) {
          this.menusAdm.push(item);
        }
        if (item.idMenu == 32) {
          this.menusAdm.push(item);
        }
      }

      this.menusCont.length = 0;
      for (let item of this.menus) {
        if (item.idMenu == 7) {
          this.menusCont.push(item);
        }
        if (item.idMenu == 8) {
          this.menusCont.push(item);
        }
        if (item.idMenu == 13) {
          this.menusCont.push(item);
        }
        
      }

      this.menusCompra.length =0;
      for (let item of this.menus) {
        if (item.idMenu == 30) {
            this.menusCompra.push(item);
      }
    }
      
      this.menusVtas.length = 0;
      for (let item of this.menus) {
        if (item.idMenu == 2) {
            this.menusVtas.push(item);
        }
        if (item.idMenu == 3) {
          this.menusVtas.push(item);
        }
        if (item.idMenu == 20) {
          this.menusVtas.push(item);
        }
      }

      this.menusEntr.length = 0;
      for (let item of this.menus) {
        if (item.idMenu == 12) {
            this.menusEntr.push(item);
        }
        
      }

      this.menusFactu.length = 0;
      for (let item of this.menus){
        if (item.idMenu == 24) {
          this.menusFactu.push(item);
        }
        if (item.idMenu == 28) {
          this.menusFactu.push(item);
        }
        if (item.idMenu == 29) {
          this.menusFactu.push(item);
        }
        if (item.idMenu == 32) {
          this.menusFactu.push(item);
        }
      }

    });

    this.datosempresaService.mensajeCambio.subscribe(data => {
      this.nombre = data.Nombre_Empresa;
      console.log(this.nombre)
    })
    
  }

  ngOnInit(){
    // Using RxJS Timer
    this.subscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe(time => {
        this.rxTime = time;
      });
  }

  getmenusAdm(){
    if(this.menusAdm.length > 0){
      this.actMenuAdm = true;
    }else{
      this.actMenuAdm = false;
    }
    return this.actMenuAdm;
  }

  getmenusCont(){
    if(this.menusCont.length > 0){
      this.actMenuCont = true;
    }else{
      this.actMenuCont = false;
    }
    return this.actMenuCont;
  }

  getmenusCompras(){
    if(this.menusCompra.length > 0){
      this.actMenuCompra = true;
    }else{
      this.actMenuCompra = false;
    }
    return this.actMenuCompra;
  }

  getmenusFac(){
    if(this.menusFactu.length > 0){
      this.actMenuFactu = true;
    }else{
      this.actMenuFactu = false;
    }
    return this.actMenuFactu;
  }

  getmenusVtas(){
    if(this.menusVtas.length > 0){
      this.actMenuVtas = true;
    }else{
      this.actMenuVtas = false;
    }
    return this.actMenuVtas;
  }

  getmenusEntr(){
    if(this.menusEntr.length > 0){
      this.actMenuEntr = true;
    }else{
      this.actMenuEntr = false;
    }
    return this.actMenuEntr;
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
