<div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      CARGANDO
    </div>
  </div>

  <div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar pedido">
    </mat-form-field>
  </div>

  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="numpedi">
      <mat-header-cell *matHeaderCellDef mat-sort-header># Pedido</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.famaord_enc.numpedi}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vendedor">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Vendedor</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.famaord_enc.ccmaven.nomruta}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nomclie" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.ccmacli.nomclie}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fecha" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.fecha | date: 'MMM d, y H:mm:ss' : '-1200'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="acciones" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="example-button-row">
            <button mat-raised-button color="primary" (click)="convertirAfactura(row)" *ngIf="estadoPedido(row)">Convertir</button>
            <div *ngIf="!estadoPedido(row)"> <h3>ANULADO</h3></div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row [ngClass]="{'make-green': row.famaord_enc.estado == 3,'make-red': row.famaord_enc.estado == 4 }" *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  <div class="example-button-row">
    <button mat-raised-button color="warm" (click)="cerrar()" >Cerrar</button>
</div>

