import { Btn } from './../../../_model/utils/btn';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css']
})
export class InventarioComponent implements OnInit {

  btns : Btn[] =[];

  constructor(private router: Router) {}

  ngOnInit() {
    let btn : Btn = new Btn();
    btn.id = 1;
    btn.name ="INGRESO INVENTARIO";
    btn.link ="ingresoinventario";
    this.btns.push(btn);

    btn = new Btn();
    btn.id = 2;
    btn.name ="VER INVENTARIO RUTAS";
    btn.link ="inventariorutas" ;
    this.btns.push(btn)

    btn = new Btn();
    btn.id = 3;
    btn.name ="IMPORTAR INVENTARIO";
    btn.link ="importarinventario";
    this.btns.push(btn)
    
  }

  openlinkbtn(link : String){
    console.log(link)
    let navigationExtras: NavigationExtras = {
      state: {
        //ord: distrucion
      }
    };
    this.router.navigate([link],navigationExtras);
  }

}
