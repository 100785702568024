<mat-tab-group>
    <mat-tab label="Datos Empresa">
        <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="nombre">
                <mat-header-cell *matHeaderCellDef mat-sort-header>EMPRESA</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.nombre_Empresa}} </mat-cell>
              </ng-container>
          
              <ng-container matColumnDef="nit" >
                <mat-header-cell *matHeaderCellDef mat-sort-header>NIT</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.nit}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="nrc" >
                <mat-header-cell *matHeaderCellDef mat-sort-header>NRC</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.nrc}} </mat-cell>
              </ng-container>
          
              <ng-container matColumnDef="acciones">
                <mat-header-cell *matHeaderCellDef > ACCIONES </mat-header-cell>
                
                <mat-cell *matCellDef="let row"> 
                  <!-- <button mat-raised-button color="primary" (click)="seleccionar(row)">CLIENTES</button>  --> 
                </mat-cell>
                  
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <!--[ngClass]="{'make-green': row.famaord_enc.estado == 3,'make-red': row.famaord_enc.estado == 4 }"--> 
                <mat-row  *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </mat-tab>
    <mat-tab label="Modulo Pedidos"> Content 2 </mat-tab>
  </mat-tab-group>