import { DetalleFacturaService } from './../../../_service/facturacion/detalle-factura.service';
import { FamaordEncService } from './../../../_service/famaord-enc.service';
import { Famaord_Enc } from './../../../_model/famaord_enc';
import { FamaordService } from './../../../_service/famaord.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Ccmaven } from 'src/app/_model/ccmaven';
import { CtMaDoc } from 'src/app/_model/ctmadoc';
import { Famadoc } from 'src/app/_model/famadoc';
import { Ord_Client } from 'src/app/_model/ord_client';
import { OrdClienteService } from 'src/app/_service/ord-cliente.service';
import { EncabeadoFacturasService } from 'src/app/_service/facturacion/encabeado-facturas.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogconvertirpedido',
  templateUrl: './dialogconvertirpedido.component.html',
  styleUrls: ['./dialogconvertirpedido.component.css']
})
export class DialogconvertirpedidoComponent implements OnInit {

  isLoadingResults = true;
  isRateLimitReached = true;

  ccmaven : Ccmaven;
  
  displayedColumns = ['numpedi', 'vendedor','nomclie','fecha','acciones'];
  dataSource: MatTableDataSource<Ord_Client>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ordClienteService : OrdClienteService,private famaordService : FamaordService,
    private encabeadoFacturasService : EncabeadoFacturasService, private detalleFacturaService : DetalleFacturaService,
    private dialogRef: MatDialogRef<DialogconvertirpedidoComponent>, private famaordEncService : FamaordEncService
  ) { 
    this.cargarPedidos();
  }

  ngOnInit(): void {
    this.ordClienteService.Ord_ClientCambio.subscribe(data => {
      //console.log(data);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = function(data, filter: string): boolean {
        return data.ccmacli.nomclie.toLowerCase().includes(filter) || data.famaord_enc.ccmaven.nomruta.toLowerCase().includes(filter);
      };
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cargarPedidos(){
    this.ordClienteService.buscarpedidosautorizados().subscribe( p_ordenes => {
        
      this.ordClienteService.Ord_ClientCambio.next(p_ordenes);

      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    })
  }

  estadoPedido(ord_Client : Ord_Client){
    if(ord_Client.famaord_enc.estado == 5){
      return false;
    }else{
      return true;
    }
  }

  convertirAfactura(ord_Client : Ord_Client){ //modificar para que lo haga el servidor
    console.log(ord_Client)
    ord_Client.famaord_enc.estado = 6;
    let ctmadoc : CtMaDoc = new CtMaDoc();
    ctmadoc.numpedi = ord_Client.famaord_enc.numpedi;
    ctmadoc.codclie = ord_Client.famaord_enc.ccmacli.codclie;
    if(ord_Client.famaord_enc.cladocu == "0"){
      ctmadoc.coddocu = "10"+ctmadoc.numpedi
      ctmadoc.tipdoca = '1';
      ctmadoc.cladoca = '0'
    }else{
      ctmadoc.coddocu = "11"+ctmadoc.numpedi
      ctmadoc.tipdoca = '1';
      ctmadoc.cladoca = '1'
    }
    ctmadoc.coment1 = ord_Client.famaord_enc.ccmacli.nomclie;
    ctmadoc.diascre = ord_Client.famaord_enc.diacred;
    ctmadoc.cladoca = ord_Client.famaord_enc.cladocu;
    ctmadoc.rutavta = ord_Client.famaord_enc.rutavta;
    ctmadoc.fecdocu = ord_Client.famaord_enc.fecpedi.substring(0, 10).concat('T00:00:00');
    ctmadoc.fecpedi = ord_Client.famaord_enc.fecpedi.substring(0, 10).concat('T00:00:00');
    ctmadoc.emihora = '';
    ctmadoc.claprod = 1;
    ctmadoc.clasnot = 'N';
    ctmadoc.comicob = 0;
    ctmadoc.comivta = 0;
    ctmadoc.conreci = '0';
    ctmadoc.descfac = ord_Client.famaord_enc.descfactu;
    ctmadoc.estadoc = '0';
    
    ctmadoc.totivav = ord_Client.famaord_enc.iva;
    
    
    console.log(ctmadoc)
    let list_famadoc : Famadoc[] = [];
    this.famaordService.buscarPorNumPedido(ctmadoc.numpedi).subscribe(detalle => {
      console.log(detalle)
      let total =0
      detalle.forEach(element => {
        let famadoc : Famadoc = new Famadoc();
        famadoc.coddocu = ctmadoc.coddocu
        famadoc.fecdocu = ctmadoc.fecdocu;
        famadoc.fecpedi = ctmadoc.fecpedi;
        famadoc.codprod = element.codprod;
        famadoc.codclie = ctmadoc.codclie;
        famadoc.numpedi = ctmadoc.numpedi;
        famadoc.diacred = ctmadoc.diascre;
        famadoc.forpago = ctmadoc.forpago;
        famadoc.rutavta = ctmadoc.rutavta;
        famadoc.porimpu = 13;
        famadoc.totunid = element.totunid;
        famadoc.valnuni = element.valnuni;
        total = total+ (element.totunid*element.valnuni)
        list_famadoc.push(famadoc);
      });
      console.log(list_famadoc)
      ctmadoc.totvtas = total;
      this.encabeadoFacturasService.registrar(ctmadoc).subscribe(p_ctmadoc => {
        console.log(p_ctmadoc);
        this.detalleFacturaService.guardarlista(list_famadoc).subscribe(p_detalle => {
          console.log(p_detalle)
          this.famaordEncService.modificar(ord_Client.famaord_enc).subscribe( ord => {
            this.cargarPedidos();
            this.dialogRef.close({event:'close'});
          })
        })
      })
      
    })
    
  }

  cerrar(){
    this.dialogRef.close()
  }
}
