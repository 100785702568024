import { map } from 'rxjs/operators';
import { CcmacliService } from './../../../_service/ccmacli.service';
import { InmapreService } from './../../../_service/inventario/inmapre.service';
import { CcmavenService } from 'src/app/_service/ccmaven.service';
import { Component, OnInit } from '@angular/core';
import { Ccmaven } from '../../../_model/ccmaven';
import { Router, ActivatedRoute } from '@angular/router';
import { Ccmacli } from 'src/app/_model/ccmacli';
import { CcmacliClasificacionService } from 'src/app/_service/ccmacli-clasificacion.service';
import { Ccmacli_Clasificacion } from 'src/app/_model/ccmacli_clasificacion';
import { DepartamentoService } from 'src/app/_service/departamento.service';
import { Departamento } from 'src/app/_model/utils/departamento';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ingresoclientes',
  templateUrl: './ingresoclientes.component.html',
  styleUrls: ['./ingresoclientes.component.css']
})
export class IngresoclientesComponent implements OnInit {
  
  ccmacli : Ccmacli = new Ccmacli();
  ccmacliClasificacion : Ccmacli_Clasificacion [] = [];
  idccmacli_clasificacion_select : number;
  departamentos : Departamento [] = [];
  departamento_select : String;
  //municipios : String [] = [];
  municipio_select : String;
  vendedor : Ccmaven;
  municipios = [];

  departamentoClie : Departamento;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private ccmacliClasificacionService : CcmacliClasificacionService,
              private departamentoService : DepartamentoService,
              private ccmavenService : CcmavenService,
              private InmapreService : InmapreService,
              private ccmacliService : CcmacliService,
            ) { 
    
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        
        this.ccmacli = this.router.getCurrentNavigation().extras.state.cliente;
        this.buscardepartamento(this.ccmacli.mhiddpto);
        this.ccmacliClasificacionService.leerPorcodigo(this.ccmacli.codclie.substring(0,2)).subscribe(p_clase =>{
          this.idccmacli_clasificacion_select = p_clase.idccmacli_clasificacion;
        })

        console.log(this.ccmacli.rutclie)
        this.ccmavenService.findbyCodruta(this.ccmacli.rutclie).subscribe(p_ccmaven => {
          this.vendedor = p_ccmaven;
        })     
        
        this.departamentoService.leerporcodigo(this.ccmacli.mhiddpto).subscribe(dep => {
          console.log(dep)
          //this.onDepartamentoChange({ value: dep });
        })

        
      
      }else{
        this.router.navigate(['clientes']);
      }
    });
  }

  ngOnInit() {
    this.ccmacliClasificacionService.listar().subscribe(clases => {
      this.ccmacliClasificacion = clases;
    })

    this.departamentoService.listaDepartamentos().subscribe(depa => {
      this.departamentos = depa;
      //console.log(this.departamentos);
    })
  }

  convertNumber(num : number){
    let strNum : String;
    if(num < 10){
      strNum = '0'.concat(num.toString());
    }else{
      strNum = num.toString();
    }
    return strNum;
  }

  leerListaPrecio(){
    this.InmapreService.importarDBF(1).subscribe(data =>{

    })
  }

  onDepartamentoChange(event: any) {
    //console.log(event)
    const selectedDepto = event.value;
    //const depto = this.departamentos.find(d => d.nomdep === selectedDepto.nom);
    //console.log(selectedDepto)
    this.listarMunicipio(selectedDepto.coddep);
    //this.municipios = depto ? depto.municipios : [];
    //this.clienteForm.get('municipio')?.setValue('');
  }

  listarMunicipio(coddep : string){
    this.departamentoService.municipos_por_departamento(coddep).subscribe(mun =>{
      this.municipios = mun;
    })
  }

  onAddCliente() {
    console.log('Agregar cliente');
    
  }

  onModCliente() {
    console.log('Modifar cliente: ');
    this.ccmacliService.modificar(this.ccmacli).subscribe(cliente => {
      console.log(cliente);
    })
    
  }

  onDeleteCliente() {
    console.log('Eliminar cliente');
  }

  buscardepartamento(idDepartamento : string){
    this.departamentoService.leerporcodigo(idDepartamento).subscribe(departamento => {
      this.departamentoClie = departamento;
      this.onDepartamentoChange({ value: departamento });
    })
  }
}
